.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.school {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.green {
  background-color: #00ff00;
  opacity:0.7;
  font-weight: bold;
}

.yellow {
  background-color: #ffff00;
  opacity:0.7;
  font-weight: bold;
}

.red {
  background-color: #ff0000;
  opacity:0.7;
  color: white;
  font-weight: bold;
}

.tooltip-self {
  position: relative;
  display: inline-block;
}

.tooltip-self .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip-self:hover .tooltiptext {
  visibility: visible;
}

table, th, td {
  border: 1px solid white;
}